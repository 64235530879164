import React from 'react';
import { graphql, Link } from 'gatsby';
import Moment from 'react-moment';
import Markdown from 'react-markdown';

import styles from './article.module.scss';
import './ckEditorStyles.scss';

import {
  FullWidthBarWithLeaves,
  ArticleNavigatorBar,
  ShareArticleBar,
  QuadripleArticleArrangement,
  CategoryNavigator,
  ArticleCard,
  ArticleTag,
} from '@components';

//Why can i export query directly from here?
export const query = graphql`
  query ArticleQuery($slug: String!, $category: String!) {
    currentArticle: strapiArticle(
      slug: { eq: $slug }
      status: { eq: "published" }
    ) {
      strapiId
      title
      description
      content
      publishedAt
      category {
        name
      }
      tags {
        id
        tag
      }
      author {
        name
      }
    }
    relatedArticles: allStrapiArticle(
      filter: { category: { name: { eq: $category } } }
      limit: 4
    ) {
      edges {
        node {
          id
          description
          title
          strapiId
          slug
          category {
            name
          }
          image {
            id
            localFile {
              childImageSharp {
                # fixed(width: 320, height: 464) {
                #   ...GatsbyImageSharpFixed
                # }
                fluid(maxWidth: 320) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Article = ({ data, pageContext, location }) => {
  const currentArticle = data.currentArticle;
  const relatedArticles = data.relatedArticles.edges.map(article => {
    const {
      node: {
        category: { name },
        description,
        image,
        title,
        id,
        slug,
      },
    } = article;

    return (
      <ArticleCard
        key={id}
        title={title}
        description={description}
        tag={name}
        image={image}
        url={slug}
      />
    );
  });
  /*
  const seo = {
    metaTitle: article.title,
    metaDescription: article.description,
    shareImage: article.image,
    article: true,
  };
  */

  const tags = currentArticle.tags?.map(e => (
    <ArticleTag tag={e.tag} key={e.tag} />
  ));

  const { category, title } = currentArticle;
  const shareArticleProps = {
    category,
    title,
    url: location.href,
    twitterHandle: '_jolynmoh',
  };
  const { nextArticle, previousArticle } = pageContext;

  const formatArticleNumber = id => (id < 10 ? `0${id}` : id);

  return (
    <>
      <div className={styles.topSection}>
        <CategoryNavigator />
        <div className={styles.postedUnder}>
          <div className={styles.inner}>
            <span>Posted under</span>
            <Link to={`/knowledge/${category.name}`}>
              <span className={styles.category}>{category.name}</span>
            </Link>
          </div>
        </div>
      </div>
      <div className={styles.articleWidthWrapper}>
        <div className={styles.articleDetails}>
          <div className={styles.articleNumber}>
            {formatArticleNumber(currentArticle.strapiId)}
          </div>
          <div className={styles.articleDate}>
            <Moment format='MMM Do YYYY, h a'>
              {currentArticle.publishedAt}
            </Moment>
          </div>
        </div>
        <div>
          <h1 className={styles.articleTitle}>{currentArticle.title}</h1>
        </div>
        <div className={styles.articleAuthor}>
          {currentArticle.author && `Written by ${currentArticle.author.name}`}
        </div>
        <div className={styles.articleContent}>
          <div className='ck-content'>
            <Markdown source={currentArticle.content} escapeHtml={false} />
          </div>
        </div>
        <div className={styles.articleTags}>
          {tags.length > 0 && `This article is tagged under`}
          {tags}
        </div>
        <div className={styles.mobileOnlyWrapper}>
          <ShareArticleBar shareArticleProps={shareArticleProps} />
        </div>
      </div>
      <FullWidthBarWithLeaves classNames={styles}>
        <ArticleNavigatorBar
          nextArticle={nextArticle}
          previousArticle={previousArticle}
          shareArticleProps={shareArticleProps}
        />
      </FullWidthBarWithLeaves>
      <div className={styles.relatedStoriesHeader}>Related Stories</div>
      <QuadripleArticleArrangement cards={relatedArticles} />
    </>
  );
};

export default Article;
